<div [ngClass]="{'open': !(isNavBarCollapsed | async)}">
    <ds-themed-header></ds-themed-header>
    <ds-themed-navbar></ds-themed-navbar>
    <div class="per_biblio_menu container">
      <a class="submenu-heading" href="https://www.uandes.cl/biblioteca/" target="_blank">Biblioteca</a>

      <ul class="secondary-menu-items">
				  <li class="page_item "><a href="/procedi">Enviar publicaciones</a></li>
				  <li class="page_item "><a href="#">Contacto</a></li>
				  <li class="page_item "><a href="#">Acerca</a></li>
      </ul>
    </div>
</div>
